import React, {useState} from 'react';

import 'swiper/css';
import 'swiper/css/pagination';

import {Swiper, SwiperSlide} from 'swiper/react';

import {
  Autoplay,
  Navigation,
} from 'swiper/modules';


export default function BannersCarousel() {
  const [controlledSwiper, setControlledSwiper] = useState(null);

  const urlsBanners = [
    'https://locare.s3.sa-east-1.amazonaws.com/sites/alpha/banners/1.png',
    'https://locare.s3.sa-east-1.amazonaws.com/sites/alpha/banners/2.png',
    'https://locare.s3.sa-east-1.amazonaws.com/sites/alpha/banners/3.png',
    'https://locare.s3.sa-east-1.amazonaws.com/sites/alpha/banners/4.png',
    'https://locare.s3.sa-east-1.amazonaws.com/sites/alpha/banners/5.png',
    'https://locare.s3.sa-east-1.amazonaws.com/sites/alpha/banners/6.png',
  ];
  
  const renderProperties = urlsBanners?.map((item, index) => (
    <SwiperSlide key={index}>
      <img width="100%" src={item} />
    </SwiperSlide>
  ));

  return (
    <section>
      <div className="container">
        <div className="items">
          <Swiper
            loop={true}
            navigation
            slidesPerView={1}
            spaceBetween={30}
            modules={[Autoplay, Navigation]}
            controller={{
              control: controlledSwiper,
            }}
            autoplay={{
              delay: 3000,
            }}
            style={{
              paddingBlock: 30,
            }}>
            {renderProperties}
          </Swiper>
        </div>
      </div>
    </section>
  );
}
